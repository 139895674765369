
import { Component, Vue, Prop } from 'vue-property-decorator';
import moment from 'moment';
import { inject } from '@/inversify';

import UserService, { UserServiceS } from '@/modules/user/user.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import ClusterCompsetsService, { ClusterCompsetsServiceS } from '@/modules/cluster/cluster-compsets.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';

import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import type Day from '@/modules/common/types/day.type';
import RatesCompsetMainModel from '@/modules/cluster/models/rates-compset-main.model';
import ScanBtnSmall from '@/modules/common/components/ui-kit/scan-btn-small.vue';

import RatesScanTooltip from './rates-scans-tooltip.vue';

@Component({
    components: {
        ScanBtnSmall,
        RatesScanTooltip,
    },
})
export default class RatesDayScanBtn extends Vue {
    @inject(RatesServiceS) private ratesService!: RatesService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(ClusterCompsetsServiceS) private clusterCompsetsService!: ClusterCompsetsService;
    @inject(ClusterServiceS) private clusterService!: ClusterService;

    @Prop({ type: Number, required: true })
    private day!: Day | undefined;

    tooltipPos: HTMLDivElement | null = null;

    get isScanning() {
        if (this.userService.isViewAsHotel) {
            return this.ratesService.scan.values.status === SCAN_STATUS.IN_PROGRESS;
        }

        const doc = this.clusterService.getMainCompsetData<RatesCompsetMainModel>(this.hotelId);
        return doc?.scan.status === SCAN_STATUS.IN_PROGRESS;
    }

    get disabled() {
        const { scanLimitation } = this.ratesCommonService;
        const haveNoScans = scanLimitation && scanLimitation.remaining === 0;
        const compsetId = this.$route.params.compsetId || this.compsetsService.currentCompset?.id;

        const isCompsetActive = this.userService.isViewAsHotel
            ? this.compsetsService.getCompset(compsetId!)?.isActive
            : this.clusterCompsetsService.getCompsetById(compsetId!)?.isActive;

        if (!isCompsetActive) return true;

        return !this.ratesService.isScanAvailable(this.day) || haveNoScans;
    }

    get hotelId() {
        return +this.$route.params.hotelId;
    }

    async handleClick() {
        if (this.isScanning || this.disabled) {
            return;
        }
        await this.ratesService.triggerScan(this.day);
    }

    // FIXME Use moment here instead
    get formatScanDate() {
        const dateScan = this.ratesService.getUpdateDate(this.day!);

        if (!dateScan) {
            return '--/--/--';
        }

        const currentDate = new Date();

        const date = dateScan.getDate();
        const month = dateScan.getMonth();
        const year = dateScan.getFullYear();

        const todayDate = currentDate.getDate();
        const todayMonth = currentDate.getMonth();
        const todayYear = currentDate.getFullYear();

        const lastDateSum = date + month + year;
        const todaySum = todayDate + todayMonth + todayYear;

        let todayOrYesterday = '';
        if (lastDateSum - todaySum === -1) {
            todayOrYesterday = this.$tc('titles.yesterday');
        } else if (lastDateSum - todaySum === 0) {
            todayOrYesterday = this.$tc('titles.today');
        }

        if (todayOrYesterday) {
            return `${todayOrYesterday} ${moment(dateScan).format('LT')}`;
        }

        return `${date}/${month + 1}/${year}`;
    }
}
